.dp-summary {
  &-card {
    @include travel-card;
    &__table {
      font-family: $font-family-number;
      width: 100%;
      td {
        border-bottom: 1px solid lighten($brand-color-5, 20);
        padding-top: 0.625rem;
        padding-bottom: 0.3125rem;
        font-weight: 600;
        &:first-child {

        }
        &:last-child {
          text-align: right;
        }
      }
    }
    &__header {
      &:not(first-child) {
        padding-top: $base-padding*0.5;
      }
    }
  }
}

//add package details scss
@include create-package-detail-modal-container;
