@use "sass:math";

.dp__review {
  @include create-main-wrapper;
  @include create-row-card;

  // @include create-card-content;
  &_main {
    padding-top: $base-padding*0.5;
    .content {
      padding-left: $base-padding*0.5;
      padding-right: 0;
    }
  }
  &_card {
    box-shadow: $row-shadow-color;
    padding-bottom: 2rem;
    .review_header_text {
      margin-right: $base-margin*0.125;
      font-size:0.90rem;
    }
   @include create-review-nav;
  }
  &_package {
    // box-shadow: $row-shadow-color;
    background-color: $light;
    margin-left: $base-margin*0.5;
    margin-right: $base-margin*0.5;
    margin-bottom: $base-margin*0.5;
    &_name {
      color: $brand-color-2;
      font-size: $font-size-h3;
      padding: math.div($base-padding, 3);
    }
  }
  &_products {
    border-bottom: 1px solid $brand-color-2;
    padding-bottom: $base-padding*0.5;
    &:last-child {
      border-bottom: none;
    }
  }
}

.dp__overview_heading,
.dp__inclusions_heading,
.dp__exclusions_heading,
.dp__remarks_heading,
.dp__cancellation_heading {
  font-size: $font-size-h4;
  color: $brand-color-2;
  padding-top: $base-padding*0.5;
  padding-bottom: math.div($base-padding, 3);
}