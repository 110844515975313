@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
@media print {
  .search__result_wrapper {
    display: none;
  }
}
.search-location-checkbox {
  padding-top: 0.2375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.multi-select-dropdown {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  position: relative;
}
.multi-select-dropdown .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}

.common-pax-info {
  width: 50%;
  border: 2px solid #eceeef;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  margin-bottom: 1rem;
}
.common-pax-info__name {
  font-weight: 600;
  color: #000;
}
.common-pax-info__email, .common-pax-info__phone-number, .common-pax-info__guest-occupancy {
  font-weight: 600;
  color: #000;
}

.dp__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.dp__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp__search-form-container_main:before, .dp__search-form-container_main:after {
  content: " ";
  display: table;
}
.dp__search-form-container_main:after {
  clear: both;
}
.dp__search-form-container_main .content {
  padding-left: 0;
}
.dp__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.dp__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-wrapper:before, .dp__search-wrapper:after {
  content: " ";
  display: table;
}
.dp__search-wrapper:after {
  clear: both;
}
.dp__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.dp__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.dp__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.dp__search-trip-type, .dp__search-location, .dp__search-date, .dp__search-traveller, .dp__search-multicity, .dp__search-nationality, .dp__search-residence-country, .dp__search-currency, .dp__additioal-option, .dp__pnr-search-location, .dp__product-selection, .dp__advance-search, .dp__advance-search-params, .dp__search-supplier, .dp__search-modify-journey, .dp__search-category {
  margin-bottom: 1rem;
}
.dp__search-category_container {
  width: 50%;
  padding-right: 2.125rem;
}
.dp__search-category_container .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
  padding-top: 0.25rem;
}
.dp__with-baggage_label {
  padding: 0.3125rem;
}
.dp__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__pnr-fields:before, .dp__pnr-fields:after {
  content: " ";
  display: table;
}
.dp__pnr-fields:after {
  clear: both;
}
.dp__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.dp__search-trip-type, .dp__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.dp__search-trip-type:before, .dp__search-trip-type:after, .dp__search-modify-journey:before, .dp__search-modify-journey:after {
  content: " ";
  display: table;
}
.dp__search-trip-type:after, .dp__search-modify-journey:after {
  clear: both;
}
.dp__search-trip-type .radio-inline, .dp__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .dp__search-trip-type .radio-inline, .dp__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.dp__search-trip-type .radio-inline input[disabled], .dp__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.dp__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.dp__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.dp__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-location:before, .dp__search-location:after {
  content: " ";
  display: table;
}
.dp__search-location:after {
  clear: both;
}
.dp__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  width: 48%;
}
.dp__search-location .search-location_exachange-icon {
  width: 3%;
  float: left;
  min-height: 1px;
  padding-top: 1rem;
}
.dp__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dp__search-date, .dp__hub-surcharge, .dp__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-date:before, .dp__search-date:after, .dp__hub-surcharge:before, .dp__hub-surcharge:after, .dp__search-date_hotel-dates:before, .dp__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.dp__search-date:after, .dp__hub-surcharge:after, .dp__search-date_hotel-dates:after {
  clear: both;
}
.dp__search-date .search-date_select, .dp__search-date_baggage-one-way, .dp__hub-surcharge .search-date_select, .dp__hub-surcharge_baggage-one-way, .dp__search-date_hotel-dates .search-date_select, .dp__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dp__search-date_baggage-one-way .baggage-label, .dp__hub-surcharge_baggage-one-way .baggage-label, .dp__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.dp__search-date_baggage-round-multicity .baggage-label, .dp__hub-surcharge_baggage-round-multicity .baggage-label, .dp__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.dp__search-date_hotel-dates .search-date_select, .dp__hub-surcharge_hotel-dates .search-date_select, .dp__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.dp__search-date .date-field-with-duration, .dp__hub-surcharge .date-field-with-duration, .dp__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.dp__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.dp__search-date-verticle:before, .dp__search-date-verticle:after {
  content: " ";
  display: table;
}
.dp__search-date-verticle:after {
  clear: both;
}
.dp__search-date-verticle .date-range-picker__from-date, .dp__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.dp__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.dp__hub-surcharge-container:before, .dp__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.dp__hub-surcharge-container:after {
  clear: both;
}
.dp__search-nationality, .dp__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-nationality:before, .dp__search-nationality:after, .dp__search-residence-country:before, .dp__search-residence-country:after {
  content: " ";
  display: table;
}
.dp__search-nationality:after, .dp__search-residence-country:after {
  clear: both;
}
.dp__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-currency:before, .dp__search-currency:after {
  content: " ";
  display: table;
}
.dp__search-currency:after {
  clear: both;
}
.dp__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.dp__search-multicity-row, .dp__search-multicity_hotel-dates, .dp__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-multicity-row:before, .dp__search-multicity-row:after, .dp__search-multicity_hotel-dates:before, .dp__search-multicity_hotel-dates:after, .dp__search-multicity_hotel-search-date:before, .dp__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.dp__search-multicity-row:after, .dp__search-multicity_hotel-dates:after, .dp__search-multicity_hotel-search-date:after {
  clear: both;
}
.dp__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.dp__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.dp__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.dp__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.dp__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.dp__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.dp__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.dp__search-multicity_date .search-date_select {
  width: 100%;
}
.dp__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.dp__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.dp__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.dp__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.dp__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.dp__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.dp__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-traveller:before, .dp__search-traveller:after {
  content: " ";
  display: table;
}
.dp__search-traveller:after {
  clear: both;
}
.dp__search-traveller:before, .dp__search-traveller:after {
  content: " ";
  display: table;
}
.dp__search-traveller:after {
  clear: both;
}
.dp__search-traveller_adult, .dp__search-traveller_child, .dp__search-traveller_infant, .dp__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.dp__search-traveller_adult input, .dp__search-traveller_child input, .dp__search-traveller_infant input, .dp__search-traveller_class input {
  text-align: center;
}
.dp__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.dp__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.dp__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.dp__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.dp__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__advance-search-params:before, .dp__advance-search-params:after {
  content: " ";
  display: table;
}
.dp__advance-search-params:after {
  clear: both;
}
.dp__advance-search-params_stops, .dp__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dp__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.dp__search-supplier:before, .dp__search-supplier:after {
  content: " ";
  display: table;
}
.dp__search-supplier:after {
  clear: both;
}
.dp__supplier-selection label {
  padding-right: 2rem;
}
.dp__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__additioal-option:before, .dp__additioal-option:after {
  content: " ";
  display: table;
}
.dp__additioal-option:after {
  clear: both;
}
.dp__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.dp__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search-submit:before, .dp__search-submit:after {
  content: " ";
  display: table;
}
.dp__search-submit:after {
  clear: both;
}
.dp__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.dp__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.dp__search-submit_button .submit_button:focus, .dp__search-submit_button .submit_button.focus, .dp__search-submit_button .submit_button:active:focus, .dp__search-submit_button .submit_button:active.focus, .dp__search-submit_button .submit_button.active:focus, .dp__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp__search-submit_button .submit_button:hover, .dp__search-submit_button .submit_button:focus, .dp__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.dp__search-submit_button .submit_button:active, .dp__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp__search-submit_button .submit_button.disabled, .dp__search-submit_button .submit_button[disabled], fieldset[disabled] .dp__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp__search-form_container input[type=text],
.dp__search-form_container input[type=number],
.dp__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.dp__search-form_container input[type=text],
.dp__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.dp__search-form_container .number-stepper {
  max-width: 250px;
}
.dp__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.dp__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.dp__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.dp__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.dp__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.dp__advertise p {
  margin-bottom: 0;
}
.dp__search-time, .dp__search-date, .dp__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.dp__search-time:before, .dp__search-time:after, .dp__search-date:before, .dp__search-date:after, .dp__input-field:before, .dp__input-field:after {
  content: " ";
  display: table;
}
.dp__search-time:after, .dp__search-date:after, .dp__input-field:after {
  clear: both;
}
.dp__search-time_select, .dp__search-date_select, .dp__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.dp__search-time_half, .dp__search-date_half, .dp__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dp__search-nav {
  background-color: #087dc2;
}
.dp__search-nav:before, .dp__search-nav:after {
  content: " ";
  display: table;
}
.dp__search-nav:after {
  clear: both;
}
.dp__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.dp__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.dp__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.dp__search-nav_list .active {
  background-color: #ec2227;
}
.dp__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .dp__search-nav_list {
    display: none;
  }
}

.dynamic-dp__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.dynamic-dp__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dynamic-dp__search-form-container_main:before, .dynamic-dp__search-form-container_main:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-form-container_main:after {
  clear: both;
}
.dynamic-dp__search-form-container_main .content {
  padding-left: 0;
}
.dynamic-dp__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dynamic-dp__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dynamic-dp__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.dynamic-dp__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dynamic-dp__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-wrapper:before, .dynamic-dp__search-wrapper:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-wrapper:after {
  clear: both;
}
.dynamic-dp__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.dynamic-dp__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.dynamic-dp__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.dynamic-dp__search-trip-type, .dynamic-dp__search-location, .dynamic-dp__search-date, .dynamic-dp__search-traveller, .dynamic-dp__search-multicity, .dynamic-dp__search-nationality, .dynamic-dp__search-residence-country, .dynamic-dp__search-currency, .dynamic-dp__additioal-option, .dynamic-dp__pnr-search-location, .dynamic-dp__product-selection, .dynamic-dp__advance-search, .dynamic-dp__advance-search-params, .dynamic-dp__search-supplier, .dynamic-dp__search-modify-journey, .dynamic-dp__search-category {
  margin-bottom: 1rem;
}
.dynamic-dp__search-category_container {
  width: 50%;
  padding-right: 2.125rem;
}
.dynamic-dp__search-category_container .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
  padding-top: 0.25rem;
}
.dynamic-dp__with-baggage_label {
  padding: 0.3125rem;
}
.dynamic-dp__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__pnr-fields:before, .dynamic-dp__pnr-fields:after {
  content: " ";
  display: table;
}
.dynamic-dp__pnr-fields:after {
  clear: both;
}
.dynamic-dp__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.dynamic-dp__search-trip-type, .dynamic-dp__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.dynamic-dp__search-trip-type:before, .dynamic-dp__search-trip-type:after, .dynamic-dp__search-modify-journey:before, .dynamic-dp__search-modify-journey:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-trip-type:after, .dynamic-dp__search-modify-journey:after {
  clear: both;
}
.dynamic-dp__search-trip-type .radio-inline, .dynamic-dp__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .dynamic-dp__search-trip-type .radio-inline, .dynamic-dp__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.dynamic-dp__search-trip-type .radio-inline input[disabled], .dynamic-dp__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.dynamic-dp__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.dynamic-dp__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.dynamic-dp__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-location:before, .dynamic-dp__search-location:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-location:after {
  clear: both;
}
.dynamic-dp__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  width: 48%;
}
.dynamic-dp__search-location .search-location_exachange-icon {
  width: 3%;
  float: left;
  min-height: 1px;
  padding-top: 1rem;
}
.dynamic-dp__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dynamic-dp__search-date, .dynamic-dp__hub-surcharge, .dynamic-dp__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-date:before, .dynamic-dp__search-date:after, .dynamic-dp__hub-surcharge:before, .dynamic-dp__hub-surcharge:after, .dynamic-dp__search-date_hotel-dates:before, .dynamic-dp__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-date:after, .dynamic-dp__hub-surcharge:after, .dynamic-dp__search-date_hotel-dates:after {
  clear: both;
}
.dynamic-dp__search-date .search-date_select, .dynamic-dp__search-date_baggage-one-way, .dynamic-dp__hub-surcharge .search-date_select, .dynamic-dp__hub-surcharge_baggage-one-way, .dynamic-dp__search-date_hotel-dates .search-date_select, .dynamic-dp__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dynamic-dp__search-date_baggage-one-way .baggage-label, .dynamic-dp__hub-surcharge_baggage-one-way .baggage-label, .dynamic-dp__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.dynamic-dp__search-date_baggage-round-multicity .baggage-label, .dynamic-dp__hub-surcharge_baggage-round-multicity .baggage-label, .dynamic-dp__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.dynamic-dp__search-date_hotel-dates .search-date_select, .dynamic-dp__hub-surcharge_hotel-dates .search-date_select, .dynamic-dp__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.dynamic-dp__search-date .date-field-with-duration, .dynamic-dp__hub-surcharge .date-field-with-duration, .dynamic-dp__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.dynamic-dp__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.dynamic-dp__search-date-verticle:before, .dynamic-dp__search-date-verticle:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-date-verticle:after {
  clear: both;
}
.dynamic-dp__search-date-verticle .date-range-picker__from-date, .dynamic-dp__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.dynamic-dp__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.dynamic-dp__hub-surcharge-container:before, .dynamic-dp__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.dynamic-dp__hub-surcharge-container:after {
  clear: both;
}
.dynamic-dp__search-nationality, .dynamic-dp__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-nationality:before, .dynamic-dp__search-nationality:after, .dynamic-dp__search-residence-country:before, .dynamic-dp__search-residence-country:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-nationality:after, .dynamic-dp__search-residence-country:after {
  clear: both;
}
.dynamic-dp__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-currency:before, .dynamic-dp__search-currency:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-currency:after {
  clear: both;
}
.dynamic-dp__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.dynamic-dp__search-multicity-row, .dynamic-dp__search-multicity_hotel-dates, .dynamic-dp__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-multicity-row:before, .dynamic-dp__search-multicity-row:after, .dynamic-dp__search-multicity_hotel-dates:before, .dynamic-dp__search-multicity_hotel-dates:after, .dynamic-dp__search-multicity_hotel-search-date:before, .dynamic-dp__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-multicity-row:after, .dynamic-dp__search-multicity_hotel-dates:after, .dynamic-dp__search-multicity_hotel-search-date:after {
  clear: both;
}
.dynamic-dp__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.dynamic-dp__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.dynamic-dp__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.dynamic-dp__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.dynamic-dp__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.dynamic-dp__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.dynamic-dp__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.dynamic-dp__search-multicity_date .search-date_select {
  width: 100%;
}
.dynamic-dp__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.dynamic-dp__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.dynamic-dp__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.dynamic-dp__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.dynamic-dp__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.dynamic-dp__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.dynamic-dp__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-traveller:before, .dynamic-dp__search-traveller:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-traveller:after {
  clear: both;
}
.dynamic-dp__search-traveller:before, .dynamic-dp__search-traveller:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-traveller:after {
  clear: both;
}
.dynamic-dp__search-traveller_adult, .dynamic-dp__search-traveller_child, .dynamic-dp__search-traveller_infant, .dynamic-dp__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.dynamic-dp__search-traveller_adult input, .dynamic-dp__search-traveller_child input, .dynamic-dp__search-traveller_infant input, .dynamic-dp__search-traveller_class input {
  text-align: center;
}
.dynamic-dp__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.dynamic-dp__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.dynamic-dp__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.dynamic-dp__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.dynamic-dp__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__advance-search-params:before, .dynamic-dp__advance-search-params:after {
  content: " ";
  display: table;
}
.dynamic-dp__advance-search-params:after {
  clear: both;
}
.dynamic-dp__advance-search-params_stops, .dynamic-dp__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dynamic-dp__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.dynamic-dp__search-supplier:before, .dynamic-dp__search-supplier:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-supplier:after {
  clear: both;
}
.dynamic-dp__supplier-selection label {
  padding-right: 2rem;
}
.dynamic-dp__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__additioal-option:before, .dynamic-dp__additioal-option:after {
  content: " ";
  display: table;
}
.dynamic-dp__additioal-option:after {
  clear: both;
}
.dynamic-dp__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.dynamic-dp__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.dynamic-dp__search-submit:before, .dynamic-dp__search-submit:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-submit:after {
  clear: both;
}
.dynamic-dp__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.dynamic-dp__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.dynamic-dp__search-submit_button .submit_button:focus, .dynamic-dp__search-submit_button .submit_button.focus, .dynamic-dp__search-submit_button .submit_button:active:focus, .dynamic-dp__search-submit_button .submit_button:active.focus, .dynamic-dp__search-submit_button .submit_button.active:focus, .dynamic-dp__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dynamic-dp__search-submit_button .submit_button:hover, .dynamic-dp__search-submit_button .submit_button:focus, .dynamic-dp__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.dynamic-dp__search-submit_button .submit_button:active, .dynamic-dp__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dynamic-dp__search-submit_button .submit_button.disabled, .dynamic-dp__search-submit_button .submit_button[disabled], fieldset[disabled] .dynamic-dp__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dynamic-dp__search-form_container input[type=text],
.dynamic-dp__search-form_container input[type=number],
.dynamic-dp__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.dynamic-dp__search-form_container input[type=text],
.dynamic-dp__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.dynamic-dp__search-form_container .number-stepper {
  max-width: 250px;
}
.dynamic-dp__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.dynamic-dp__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.dynamic-dp__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.dynamic-dp__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.dynamic-dp__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.dynamic-dp__advertise p {
  margin-bottom: 0;
}
.dynamic-dp__search-time, .dynamic-dp__search-date, .dynamic-dp__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.dynamic-dp__search-time:before, .dynamic-dp__search-time:after, .dynamic-dp__search-date:before, .dynamic-dp__search-date:after, .dynamic-dp__input-field:before, .dynamic-dp__input-field:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-time:after, .dynamic-dp__search-date:after, .dynamic-dp__input-field:after {
  clear: both;
}
.dynamic-dp__search-time_select, .dynamic-dp__search-date_select, .dynamic-dp__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.dynamic-dp__search-time_half, .dynamic-dp__search-date_half, .dynamic-dp__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.dynamic-dp__search-nav {
  background-color: #087dc2;
}
.dynamic-dp__search-nav:before, .dynamic-dp__search-nav:after {
  content: " ";
  display: table;
}
.dynamic-dp__search-nav:after {
  clear: both;
}
.dynamic-dp__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.dynamic-dp__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.dynamic-dp__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.dynamic-dp__search-nav_list .active {
  background-color: #ec2227;
}
.dynamic-dp__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .dynamic-dp__search-nav_list {
    display: none;
  }
}

.dp__search_result:before, .dp__search_result:after {
  content: " ";
  display: table;
}
.dp__search_result:after {
  clear: both;
}
.dp__search_result .dp-search__result {
  color: #087dc2;
}
.dp__search_result .dp-search__result_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp__search_result .dp-search__result_main:before, .dp__search_result .dp-search__result_main:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_main:after {
  clear: both;
}
.dp__search_result .dp-search__result_main .content {
  padding-left: 0;
}
.dp__search_result .dp-search__result_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp__search_result .dp-search__result_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp__search_result .dp-search__result_wrapper {
    max-width: 1400px;
  }
}
.dp__search_result .dp-search__result_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp__search_result .dp-search__result_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.dp__search_result .dp-search__result_row:before, .dp__search_result .dp-search__result_row:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_row:after {
  clear: both;
}
.dp__search_result .dp-search__result_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.dp__search_result .dp-search__result_card:before, .dp__search_result .dp-search__result_card:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_card:after {
  clear: both;
}
.dp__search_result .dp-search__result_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.dp__search_result .dp-search__result_footer:before, .dp__search_result .dp-search__result_footer:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_footer:after {
  clear: both;
}
.dp__search_result .dp-search__result_tax-header {
  margin: 0 15px 0 1rem;
  padding: 0.25rem;
  color: #1d771d;
  background-color: #F5D76E;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}
.dp__search_result .dp-search__result_tax-header:before, .dp__search_result .dp-search__result_tax-header:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_tax-header:after {
  clear: both;
}
.dp__search_result .dp-search__result_tax-header .tax-message {
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.dp__search_result .dp-search__result_footer {
  background-color: #f0f5f8;
}
.dp__search_result .dp-search__result_footer:before, .dp__search_result .dp-search__result_footer:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_footer:after {
  clear: both;
}
.dp__search_result .dp-search__result_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.dp__search_result .dp-search__result_title {
  padding-bottom: 2px;
  padding-top: 0.3125rem;
  margin-left: -0.3125rem;
  border-bottom: 1px solid #d0d0d0;
}
.dp__search_result .dp-search__result_title:before, .dp__search_result .dp-search__result_title:after {
  content: " ";
  display: table;
}
.dp__search_result .dp-search__result_title:after {
  clear: both;
}
.dp__search_result .dp-search__result_heading {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-left: -0.3125rem;
  text-transform: capitalize;
}
.dp__search_result .dp-search__result_row {
  margin-left: -0.3125rem;
}
.dp__search_result .dp-search__result .flight-details {
  margin-right: -13px;
}
.dp__search_result .dp-search__result .flight-details .tab-content {
  margin-top: 0.5rem;
}
.dp__search_result .dp-search__result .flight-details .result__flight_details_button {
  color: #000;
}
.dp__search_result .dp-search__result .flight-details .result__extra_details .details-content {
  float: right;
}
.dp__search_result .dp-search__result .flight-details .result__hotel_details_button,
.dp__search_result .dp-search__result .flight-details .result__extra_details_cancellation,
.dp__search_result .dp-search__result .flight-details .result__extra_details_hotel-highlights {
  color: #087dc2;
  text-transform: uppercase;
}
.dp__search_result .dp-search__result .flight-details .search__detail_flight {
  color: #838383;
}
.dp__search_result .dp-search__result .flight-details__tab-content_error-container .alert__message {
  margin-bottom: 0;
}
.dp__search_result .dp-search__result .search__result_card {
  margin-bottom: 0.3125rem;
}

.dp-results__dp_name {
  color: #087dc2;
  float: left;
  font-size: 1rem;
  font-family: "Open Sans";
  text-transform: uppercase;
}
.dp-results__dp_type, .dp-results__dp_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  text-align: center;
  float: right;
  color: #087dc2;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.dp-results__dp_type {
  background-color: #c7e5f6;
}
.dp-results__products {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 83.3333333333%;
  padding-bottom: 0.9375rem;
  padding-left: 0;
  padding-right: 0;
}
.dp-results__products:before, .dp-results__products:after {
  content: " ";
  display: table;
}
.dp-results__products:after {
  clear: both;
}
.dp-results__book {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  text-align: center;
}
.dp-results__book_button {
  padding: 0.3125rem 0;
  font-size: 10px;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  margin-right: -0.3125rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
}
.dp-results__book_button:focus, .dp-results__book_button.focus, .dp-results__book_button:active:focus, .dp-results__book_button:active.focus, .dp-results__book_button.active:focus, .dp-results__book_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp-results__book_button:hover, .dp-results__book_button:focus, .dp-results__book_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.dp-results__book_button:active, .dp-results__book_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp-results__book_button.disabled, .dp-results__book_button[disabled], fieldset[disabled] .dp-results__book_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp-results__dp_details_button {
  padding: 0.3125rem 0;
  font-size: 10px;
  line-height: inherit;
  border-radius: 0;
  color: #000;
  background-color: #D6EFB6;
  width: 100%;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.dp-results__dp_details_button:focus, .dp-results__dp_details_button.focus, .dp-results__dp_details_button:active:focus, .dp-results__dp_details_button:active.focus, .dp-results__dp_details_button.active:focus, .dp-results__dp_details_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp-results__dp_details_button:hover, .dp-results__dp_details_button:focus, .dp-results__dp_details_button.focus {
  color: #000;
  text-decoration: none;
  background-color: #a7dd62;
}
.dp-results__dp_details_button:active, .dp-results__dp_details_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp-results__dp_details_button.disabled, .dp-results__dp_details_button[disabled], fieldset[disabled] .dp-results__dp_details_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp-results__price {
  margin-bottom: 0.625rem;
}
.dp-results__price_amount {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.dp-results__customize-package {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 83.3333333333%;
}
.dp-results__customize-package_button {
  color: #c7e5f6;
  background-color: #087dc2;
  padding: 0.3125rem 0;
  font-size: 10px;
  line-height: inherit;
  border-radius: 0;
  font-weight: 700;
  width: 23%;
  float: right;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  margin-right: 0.625rem;
}
.dp-results__customize-package_button:focus, .dp-results__customize-package_button.focus, .dp-results__customize-package_button:active:focus, .dp-results__customize-package_button:active.focus, .dp-results__customize-package_button.active:focus, .dp-results__customize-package_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp-results__customize-package_button:hover, .dp-results__customize-package_button:focus, .dp-results__customize-package_button.focus {
  color: #c7e5f6;
  text-decoration: none;
  background-color: #043e60;
}
.dp-results__customize-package_button:active, .dp-results__customize-package_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp-results__customize-package_button.disabled, .dp-results__customize-package_button[disabled], fieldset[disabled] .dp-results__customize-package_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp-results__customize-package_button:first-child {
  margin-right: 0;
}

.product {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0;
}
.product-active .product__container {
  color: #087dc2;
  border: 2px solid #80CEFE;
  width: 100%;
  float: left;
  height: 100px;
  border-radius: 7px;
}
.product-active .product__container_icon {
  width: 100%;
  background-color: #f0f5f8;
  border-bottom: 2px solid #80CEFE;
  height: 70%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  padding-top: 1rem;
}
.product-active .product__container_name {
  text-align: center;
  padding: 5px;
}
.product__container {
  color: #838383;
  border: 2px solid #9d9d9d;
  width: 100%;
  float: left;
  height: 100px;
  border-radius: 7px;
}
.product__container:before, .product__container:after {
  content: " ";
  display: table;
}
.product__container:after {
  clear: both;
}
.product__container_icon {
  width: 100%;
  background-color: #f0f5f8;
  border-bottom: 2px solid #9d9d9d;
  height: 70%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  padding-top: 1rem;
}
.product__container_name {
  text-align: center;
  padding: 5px;
}

.dp__search_result .dp-search__result .transfer-search-result-container__content {
  width: 100%;
}
.dp__search_result .dp-search__result .transfer-search-result-container__content .search__result_header {
  text-transform: uppercase;
  color: #087dc2;
  border-bottom: 1px solid #aac7d8;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  margin-bottom: 0.375rem;
  margin-left: -0.9375rem;
  margin-right: -15px;
}
.dp__search_result .dp-search__result .transfer-search-result-container__content .search__result_row {
  position: relative;
}
.dp__search_result .dp-search__result .transfer-search-result-container__content .transfer-content__transfer-price_data {
  width: 18%;
}

.highlights {
  cursor: pointer;
  color: #087dc2;
}

.dp__search_result .search-info {
  background-color: #087dc2;
}
.dp__search_result .search-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp__search_result .search-info_main:before, .dp__search_result .search-info_main:after {
  content: " ";
  display: table;
}
.dp__search_result .search-info_main:after {
  clear: both;
}
.dp__search_result .search-info_main .content {
  padding-left: 0;
}
.dp__search_result .search-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp__search_result .search-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp__search_result .search-info_wrapper {
    max-width: 1400px;
  }
}
.dp__search_result .search-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp__search_result .search-info_wrapper:before, .dp__search_result .search-info_wrapper:after {
  content: " ";
  display: table;
}
.dp__search_result .search-info_wrapper:after {
  clear: both;
}
.dp__search_result .search-info__data {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  float: left;
  color: #f0f5f8;
  font-size: 1rem;
  display: block;
}
.dp__search_result .search-info__modify {
  padding-top: 1px;
  padding-bottom: 1px;
  float: right;
}
.dp__search_result .search-info__modify_button {
  padding: 0.3125rem 0.9375rem;
  font-size: 1rem;
  line-height: inherit;
  border-radius: 0;
  color: #087dc2;
  background-color: #c7e5f6;
}
.dp__search_result .search-info__modify_button:focus, .dp__search_result .search-info__modify_button.focus, .dp__search_result .search-info__modify_button:active:focus, .dp__search_result .search-info__modify_button:active.focus, .dp__search_result .search-info__modify_button.active:focus, .dp__search_result .search-info__modify_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp__search_result .search-info__modify_button:hover, .dp__search_result .search-info__modify_button:focus, .dp__search_result .search-info__modify_button.focus {
  color: #087dc2;
  text-decoration: none;
  background-color: #9bd1ef;
}
.dp__search_result .search-info__modify_button:active, .dp__search_result .search-info__modify_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp__search_result .search-info__modify_button.disabled, .dp__search_result .search-info__modify_button[disabled], fieldset[disabled] .dp__search_result .search-info__modify_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp__search_result .search-details {
  background-color: #fff;
}
.dp__search_result .search-details_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp__search_result .search-details_main:before, .dp__search_result .search-details_main:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details_main:after {
  clear: both;
}
.dp__search_result .search-details_main .content {
  padding-left: 0;
}
.dp__search_result .search-details_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp__search_result .search-details_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp__search_result .search-details_wrapper {
    max-width: 1400px;
  }
}
.dp__search_result .search-details_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp__search_result .search-details_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}
.dp__search_result .search-details__row:before, .dp__search_result .search-details__row:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details__row:after {
  clear: both;
}
.dp__search_result .search-details__row > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  padding-left: 0;
  padding-right: 0;
  border-right: 2px solid #c7e5f6;
}
.dp__search_result .search-details__row > li:first-child {
  border-left: 2px solid #c7e5f6;
}
.dp__search_result .search-details__row > li:nth-child(3) {
  border-right: none;
}
.dp__search_result .search-details__row > li:last-child {
  padding-right: 0.9375rem;
  border-right: none;
}
.dp__search_result .search-details__row .search-details__multicity {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0;
}
.dp__search_result .search-details__row .search-details__duration {
  border-right: none;
}
.dp__search_result .search-details__row_traveller {
  margin-right: -15px;
}
.dp__search_result .search-details .checkin__data,
.dp__search_result .search-details .checkout__data,
.dp__search_result .search-details .destination__data {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.dp__search_result .search-details .checkin__data:before, .dp__search_result .search-details .checkin__data:after,
.dp__search_result .search-details .checkout__data:before,
.dp__search_result .search-details .checkout__data:after,
.dp__search_result .search-details .destination__data:before,
.dp__search_result .search-details .destination__data:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details .checkin__data:after,
.dp__search_result .search-details .checkout__data:after,
.dp__search_result .search-details .destination__data:after {
  clear: both;
}
.dp__search_result .search-details .checkin__data_icon,
.dp__search_result .search-details .checkout__data_icon,
.dp__search_result .search-details .destination__data_icon {
  float: left;
  font-size: 2.25rem;
  width: 20%;
}
.dp__search_result .search-details .checkin__data_icon-multicity,
.dp__search_result .search-details .checkout__data_icon-multicity,
.dp__search_result .search-details .destination__data_icon-multicity {
  float: left;
  font-size: 2.25rem;
  width: 10%;
}
.dp__search_result .search-details .checkin__data_detail,
.dp__search_result .search-details .checkout__data_detail,
.dp__search_result .search-details .destination__data_detail {
  float: left;
  padding: 9px;
  padding-right: 0;
  width: 80%;
}
.dp__search_result .search-details .checkin__data_detail .location,
.dp__search_result .search-details .checkin__data_detail .check-in,
.dp__search_result .search-details .checkin__data_detail .check-out,
.dp__search_result .search-details .checkout__data_detail .location,
.dp__search_result .search-details .checkout__data_detail .check-in,
.dp__search_result .search-details .checkout__data_detail .check-out,
.dp__search_result .search-details .destination__data_detail .location,
.dp__search_result .search-details .destination__data_detail .check-in,
.dp__search_result .search-details .destination__data_detail .check-out {
  color: #087dc2;
}
.dp__search_result .search-details .checkin__data_detail .departure:before, .dp__search_result .search-details .checkin__data_detail .departure:after,
.dp__search_result .search-details .checkin__data_detail .checkin-date:before,
.dp__search_result .search-details .checkin__data_detail .checkin-date:after,
.dp__search_result .search-details .checkin__data_detail .checkout-date:before,
.dp__search_result .search-details .checkin__data_detail .checkout-date:after,
.dp__search_result .search-details .checkout__data_detail .departure:before,
.dp__search_result .search-details .checkout__data_detail .departure:after,
.dp__search_result .search-details .checkout__data_detail .checkin-date:before,
.dp__search_result .search-details .checkout__data_detail .checkin-date:after,
.dp__search_result .search-details .checkout__data_detail .checkout-date:before,
.dp__search_result .search-details .checkout__data_detail .checkout-date:after,
.dp__search_result .search-details .destination__data_detail .departure:before,
.dp__search_result .search-details .destination__data_detail .departure:after,
.dp__search_result .search-details .destination__data_detail .checkin-date:before,
.dp__search_result .search-details .destination__data_detail .checkin-date:after,
.dp__search_result .search-details .destination__data_detail .checkout-date:before,
.dp__search_result .search-details .destination__data_detail .checkout-date:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details .checkin__data_detail .departure:after,
.dp__search_result .search-details .checkin__data_detail .checkin-date:after,
.dp__search_result .search-details .checkin__data_detail .checkout-date:after,
.dp__search_result .search-details .checkout__data_detail .departure:after,
.dp__search_result .search-details .checkout__data_detail .checkin-date:after,
.dp__search_result .search-details .checkout__data_detail .checkout-date:after,
.dp__search_result .search-details .destination__data_detail .departure:after,
.dp__search_result .search-details .destination__data_detail .checkin-date:after,
.dp__search_result .search-details .destination__data_detail .checkout-date:after {
  clear: both;
}
.dp__search_result .search-details .checkin__data_detail .departure__detail,
.dp__search_result .search-details .checkout__data_detail .departure__detail,
.dp__search_result .search-details .destination__data_detail .departure__detail {
  float: left;
}
.dp__search_result .search-details .checkin__data_detail .departure__detail:before, .dp__search_result .search-details .checkin__data_detail .departure__detail:after,
.dp__search_result .search-details .checkout__data_detail .departure__detail:before,
.dp__search_result .search-details .checkout__data_detail .departure__detail:after,
.dp__search_result .search-details .destination__data_detail .departure__detail:before,
.dp__search_result .search-details .destination__data_detail .departure__detail:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details .checkin__data_detail .departure__detail:after,
.dp__search_result .search-details .checkout__data_detail .departure__detail:after,
.dp__search_result .search-details .destination__data_detail .departure__detail:after {
  clear: both;
}
.dp__search_result .search-details .checkin__data_detail .departure__detail_city,
.dp__search_result .search-details .checkout__data_detail .departure__detail_city,
.dp__search_result .search-details .destination__data_detail .departure__detail_city {
  margin-left: 2px;
}
.dp__search_result .search-details .checkin__data_detail .departure__detail_separator,
.dp__search_result .search-details .checkout__data_detail .departure__detail_separator,
.dp__search_result .search-details .destination__data_detail .departure__detail_separator {
  margin-right: 2px;
}
.dp__search_result .search-details .destination__data_icon span.glyphicon.glyphicon-arrow-right,
.dp__search_result .search-details .destination__data_icon span.glyphicon.glyphicon-arrow-left,
.dp__search_result .search-details .destination__data_icon span.glyphicon.glyphicon-map-marker,
.dp__search_result .search-details .destination__data_icon span.glyphicon.glyphicon-calendar,
.dp__search_result .search-details .checkin__data_icon span.glyphicon.glyphicon-arrow-right,
.dp__search_result .search-details .checkin__data_icon span.glyphicon.glyphicon-arrow-left,
.dp__search_result .search-details .checkin__data_icon span.glyphicon.glyphicon-map-marker,
.dp__search_result .search-details .checkin__data_icon span.glyphicon.glyphicon-calendar,
.dp__search_result .search-details .checkout__data_icon span.glyphicon.glyphicon-arrow-right,
.dp__search_result .search-details .checkout__data_icon span.glyphicon.glyphicon-arrow-left,
.dp__search_result .search-details .checkout__data_icon span.glyphicon.glyphicon-map-marker,
.dp__search_result .search-details .checkout__data_icon span.glyphicon.glyphicon-calendar {
  top: 5px;
}
.dp__search_result .search-details .traveller__row:before, .dp__search_result .search-details .traveller__row:after {
  content: " ";
  display: table;
}
.dp__search_result .search-details .traveller__row:after {
  clear: both;
}
.dp__search_result .search-details .traveller__row > li {
  text-align: center;
  width: 33.33%;
  border-left: 2px solid #c7e5f6;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
  height: 60px;
}
.dp__search_result .search-details .traveller__row > li:last-child {
  border-right: 2px solid #c7e5f6;
}

.passenger-info-modal .modal-header {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  font-size: 1.125rem;
  color: #087dc2;
  padding-top: 0;
  padding-bottom: 0.625rem;
}
.passenger-info-modal_header {
  padding-left: 0.9375rem;
}
.passenger-info-modal input[type=text],
.passenger-info-modal select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
}
.passenger-info-modal input[type=text] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.passenger-info-modal label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}

.dp-change-product .modal__content {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 3rem;
  max-height: 500px;
}
.dp-change-product .modal-body {
  padding-right: 0px;
  padding-left: 0px;
}

.dp-hotel-highlights {
  height: 500px;
}
.dp-hotel-highlights:before, .dp-hotel-highlights:after {
  content: " ";
  display: table;
}
.dp-hotel-highlights:after {
  clear: both;
}
.dp-hotel-highlights .highlights-modal__hotel-details {
  float: right;
  width: 75%;
  height: 100%;
  margin-top: 10px;
}
.dp-hotel-highlights .sidebar__back-button {
  margin: 20px;
  background-color: white;
  border: 5px solid #087dc2;
  padding: 25px;
}
.dp-hotel-highlights .sidebar__back-button button {
  cursor: pointer;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  background-color: #ec2227;
  color: #fff;
  display: block;
}
.dp-hotel-highlights .sidebar__back-button button:hover {
  background-color: #9b0d11;
}
.dp-hotel-highlights .sidebar__tabs {
  width: 100%;
  height: 80%;
  padding-bottom: 10px;
}
.dp-hotel-highlights .sidebar__tabs > li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  position: relative;
}
.dp-hotel-highlights .sidebar__tabs > li > a {
  color: #087dc2;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.dp-hotel-highlights .sidebar__tabs > li:hover {
  background-color: #087dc2;
}
.dp-hotel-highlights .sidebar__tabs > li:hover a {
  color: #fff;
}
.dp-hotel-highlights .sidebar__tabs > li:after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 100%;
}
.dp-hotel-highlights .sidebar__tabs .active {
  background-color: #fff;
}
.dp-hotel-highlights .sidebar__tabs .active a {
  color: #ec2227;
}
.dp-hotel-highlights .hotel-details__hotel-info {
  width: 100%;
  height: 20%;
}
.dp-hotel-highlights .hotel-details__hotel-info:before, .dp-hotel-highlights .hotel-details__hotel-info:after {
  content: " ";
  display: table;
}
.dp-hotel-highlights .hotel-details__hotel-info:after {
  clear: both;
}
.dp-hotel-highlights .hotel-details__content {
  width: 100%;
  height: 80%;
  padding-left: 15px;
}
.dp-hotel-highlights .hotel-info__hotel-image {
  float: left;
  padding-left: 15px;
  width: 20%;
}
.dp-hotel-highlights .hotel-info__hotel-image .result__thumbnail {
  width: 100%;
  height: 90px;
}
.dp-hotel-highlights .hotel-info__hotel-description {
  float: left;
  width: 80%;
}
.dp-hotel-highlights .description__hotel {
  padding-left: 10px;
  width: 100%;
}
.dp-hotel-highlights .description__hotel_name {
  color: #087dc2;
  font-weight: 400;
  text-align: left;
  font-size: 18px;
}
.dp-hotel-highlights .hotel-slideshow__header {
  min-height: 30px;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.dp__search_result .hotel-room-detail__room-filter {
  width: 25%;
}
.dp__search_result .hotel-room-detail__container-view {
  width: 75%;
}
.dp__search_result .hotel-room-detail__toggle-filter-btn {
  width: 15%;
}
.dp__search_result .highlights-modal__sidebar {
  float: left;
  height: 3rem;
  top: 5.5rem;
  position: fixed;
  width: 100%;
  padding-right: 2rem;
}
.dp__search_result .highlights-modal__sidebar_hotel-name {
  width: 50%;
}
.dp__search_result .highlights-modal__sidebar_tabs {
  width: 50%;
}
.dp__search_result .highlights-modal__sidebar_tabs > li {
  font-size: 0.62rem;
}
.dp__search_result .highlights-modal__menu {
  top: 3rem;
}

.dp__review_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp__review_main:before, .dp__review_main:after {
  content: " ";
  display: table;
}
.dp__review_main:after {
  clear: both;
}
.dp__review_main .content {
  padding-left: 0;
}
.dp__review_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp__review_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp__review_wrapper {
    max-width: 1400px;
  }
}
.dp__review_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp__review_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.dp__review_card:before, .dp__review_card:after {
  content: " ";
  display: table;
}
.dp__review_card:after {
  clear: both;
}
.dp__review_main {
  padding-top: 0.9375rem;
}
.dp__review_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.dp__review_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;
}
.dp__review_card .review_header_text {
  margin-right: 0.234375rem;
  font-size: 0.9rem;
}
.dp__review_card .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.dp__review_card .nav-tabs {
  border-bottom: none;
}
.dp__review_card .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.dp__review_card .nav > li > a:hover,
.dp__review_card .nav > li > a:focus {
  background-color: #fff;
}
.dp__review_card .nav-tabs > li.active > a,
.dp__review_card .nav-tabs > li.active > a:hover,
.dp__review_card .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}
.dp__review_package {
  background-color: #fff;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.dp__review_package_name {
  color: #087dc2;
  font-size: 1.125rem;
  padding: 0.625rem;
}
.dp__review_products {
  border-bottom: 1px solid #087dc2;
  padding-bottom: 0.9375rem;
}
.dp__review_products:last-child {
  border-bottom: none;
}

.dp__overview_heading,
.dp__inclusions_heading,
.dp__exclusions_heading,
.dp__remarks_heading,
.dp__cancellation_heading {
  font-size: 0.875rem;
  color: #087dc2;
  padding-top: 0.9375rem;
  padding-bottom: 0.625rem;
}

.dp-summary-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.dp-summary-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.dp-summary-card__product-header {
  background-color: #EEEFF3;
  padding-left: 0.725rem;
  padding-right: 0.725rem;
  margin-top: -6px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-top: 1px solid #087dc2;
  border-bottom: 1px solid #087dc2 !important;
}
.dp-summary-card__product-header:before, .dp-summary-card__product-header:after {
  content: " ";
  display: table;
}
.dp-summary-card__product-header:after {
  clear: both;
}
.dp-summary-card__product-header_index {
  float: left;
  width: 50%;
  text-align: left;
  color: #087dc2;
}
.dp-summary-card__product-header_product-type {
  float: left;
  width: 50%;
  text-align: right;
  text-transform: uppercase;
  color: #087dc2;
}
.dp-summary-card__table {
  font-family: "Open Sans";
  width: 100%;
}
.dp-summary-card__table td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.dp-summary-card__table td:last-child {
  text-align: right;
}
.dp-summary-card__header:not(first-child) {
  padding-top: 0.9375rem;
}

.package-rate-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
}
.package-rate-details__content {
  background-color: #f0f5f8;
  border-left: 10px solid #c7e5f6;
  border-right: 10px solid #c7e5f6;
  border-top: 10px solid #c7e5f6;
  border-bottom: 10px solid #c7e5f6;
}

.package-table {
  background-color: #fff;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  table-layout: fixed;
}
.package-table tr {
  padding: 0.3125rem;
  border-bottom: 1px solid #f0f5f8;
}
.package-table tr:last-child {
  border-bottom: 0;
}
.package-table th {
  border-bottom: 0;
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.package-table td,
.package-table th {
  padding-left: 0.3125rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  text-align: left;
}
.package-table td > .result__book_button {
  width: 70%;
  margin-left: 20%;
}
.package-table td > a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  color: #087dc2;
  font-size: 11px;
}
.package-table th > button {
  background: transparent;
  border: none;
  padding-right: 1rem;
  float: right;
}
.package-table td:nth-child(4),
.package-table th:nth-child(4) {
  text-align: right;
}
.package-table td:nth-child(4) {
  padding-left: 3rem;
}
.package-table__package-rate {
  color: #000;
}
.package-table__currency {
  float: left;
}

.package-details-modal .modal__content {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.package-details-modal .modal-dialog {
  width: 80%;
  margin: 30px auto;
}
.package-details-modal .modal-header {
  padding-bottom: 0.3125rem;
  padding-right: 0;
  padding-left: 0;
  border-bottom: 0;
}
.package-details-modal .modal-header:before, .package-details-modal .modal-header:after {
  content: " ";
  display: table;
}
.package-details-modal .modal-header:after {
  clear: both;
}
.package-details-modal .modal-title {
  color: #087dc2;
  padding-bottom: 0.375rem;
}
.package-details-modal .modal-title > button {
  float: right;
  background-color: #fff;
}

.package-details,
.package-hotel-highlights,
.dynamic-package-details {
  height: 500px;
  /*.modal-content {
  	height:500px;
   padding-top: 0px;
   padding-bottom: 0px;
   margin-bottom: 0px;
  	margin-top: 0px;

  }*/
}
.package-details:before, .package-details:after,
.package-hotel-highlights:before,
.package-hotel-highlights:after,
.dynamic-package-details:before,
.dynamic-package-details:after {
  content: " ";
  display: table;
}
.package-details:after,
.package-hotel-highlights:after,
.dynamic-package-details:after {
  clear: both;
}
.package-details .highlights__sidebar,
.package-hotel-highlights .highlights__sidebar,
.dynamic-package-details .highlights__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.package-details .highlights__hotel-details,
.package-hotel-highlights .highlights__hotel-details,
.dynamic-package-details .highlights__hotel-details {
  float: right;
  width: 75%;
  height: 100%;
  margin-top: 0;
}
.package-details .sidebar__back-button:before, .package-details .sidebar__back-button:after,
.package-hotel-highlights .sidebar__back-button:before,
.package-hotel-highlights .sidebar__back-button:after,
.dynamic-package-details .sidebar__back-button:before,
.dynamic-package-details .sidebar__back-button:after {
  content: " ";
  display: table;
}
.package-details .sidebar__back-button:after,
.package-hotel-highlights .sidebar__back-button:after,
.dynamic-package-details .sidebar__back-button:after {
  clear: both;
}
.package-details .sidebar__back-button .triangle__container,
.package-hotel-highlights .sidebar__back-button .triangle__container,
.dynamic-package-details .sidebar__back-button .triangle__container {
  border-radius: 100%;
  background-color: #ec2227;
  position: relative;
  float: right;
  width: 25px;
  height: 25px;
}
.package-details .sidebar__back-button .triangle,
.package-hotel-highlights .sidebar__back-button .triangle,
.dynamic-package-details .sidebar__back-button .triangle {
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
  position: absolute;
  top: 5px;
  left: 1px;
}
.package-details .sidebar__tabs,
.package-hotel-highlights .sidebar__tabs,
.dynamic-package-details .sidebar__tabs {
  width: 100%;
  height: 80%;
  padding-bottom: 10px;
}
.package-details .sidebar__tabs > li,
.package-hotel-highlights .sidebar__tabs > li,
.dynamic-package-details .sidebar__tabs > li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  position: relative;
}
.package-details .sidebar__tabs > li > a,
.package-hotel-highlights .sidebar__tabs > li > a,
.dynamic-package-details .sidebar__tabs > li > a {
  color: #087dc2;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.package-details .sidebar__tabs > li .sidebar__back-button,
.package-hotel-highlights .sidebar__tabs > li .sidebar__back-button,
.dynamic-package-details .sidebar__tabs > li .sidebar__back-button {
  color: #ec2227;
}
.package-details .sidebar__tabs > li .sidebar__back-button:hover,
.package-hotel-highlights .sidebar__tabs > li .sidebar__back-button:hover,
.dynamic-package-details .sidebar__tabs > li .sidebar__back-button:hover {
  color: #fff;
}
.package-details .sidebar__tabs > li:hover,
.package-hotel-highlights .sidebar__tabs > li:hover,
.dynamic-package-details .sidebar__tabs > li:hover {
  background-color: #087dc2;
}
.package-details .sidebar__tabs > li:hover a,
.package-hotel-highlights .sidebar__tabs > li:hover a,
.dynamic-package-details .sidebar__tabs > li:hover a {
  color: #fff;
}
.package-details .sidebar__tabs > li:after,
.package-hotel-highlights .sidebar__tabs > li:after,
.dynamic-package-details .sidebar__tabs > li:after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 100%;
}
.package-details .sidebar__tabs .active,
.package-hotel-highlights .sidebar__tabs .active,
.dynamic-package-details .sidebar__tabs .active {
  background-color: #fff;
}
.package-details .sidebar__tabs .active a,
.package-hotel-highlights .sidebar__tabs .active a,
.dynamic-package-details .sidebar__tabs .active a {
  color: #ec2227;
}
.package-details .modal__content,
.package-hotel-highlights .modal__content,
.dynamic-package-details .modal__content {
  height: 500px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.package-details .modal__content_loader,
.package-hotel-highlights .modal__content_loader,
.dynamic-package-details .modal__content_loader {
  padding: 15px;
}
.package-details__header,
.package-hotel-highlights__header,
.dynamic-package-details__header {
  color: #087dc2;
  text-transform: uppercase;
  background-color: #c7e5f6;
  padding: 0.625rem;
}
.package-details__header > span,
.package-hotel-highlights__header > span,
.dynamic-package-details__header > span {
  float: right;
  text-transform: capitalize;
}
.package-details .package-detail__sidebar,
.package-details .package-hotel-highlights__sidebar,
.package-details .dynamic-package-detail__sidebar,
.package-hotel-highlights .package-detail__sidebar,
.package-hotel-highlights .package-hotel-highlights__sidebar,
.package-hotel-highlights .dynamic-package-detail__sidebar,
.dynamic-package-details .package-detail__sidebar,
.dynamic-package-details .package-hotel-highlights__sidebar,
.dynamic-package-details .dynamic-package-detail__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.package-details .package-detail__content,
.package-details .package-hotel-highlights__content,
.package-details .dynamic-package-detail__content,
.package-hotel-highlights .package-detail__content,
.package-hotel-highlights .package-hotel-highlights__content,
.package-hotel-highlights .dynamic-package-detail__content,
.dynamic-package-details .package-detail__content,
.dynamic-package-details .package-hotel-highlights__content,
.dynamic-package-details .dynamic-package-detail__content {
  float: right;
  width: 75%;
  height: 100%;
  overflow: auto;
  margin-top: 0;
  padding: 0.3125rem;
}
.package-details .package-overview__header,
.package-details .package-incl-excl__header,
.package-details .package-itinerary__header,
.package-details .package-hotel-description__header,
.package-details .package-canc-policy__header,
.package-hotel-highlights .package-overview__header,
.package-hotel-highlights .package-incl-excl__header,
.package-hotel-highlights .package-itinerary__header,
.package-hotel-highlights .package-hotel-description__header,
.package-hotel-highlights .package-canc-policy__header,
.dynamic-package-details .package-overview__header,
.dynamic-package-details .package-incl-excl__header,
.dynamic-package-details .package-itinerary__header,
.dynamic-package-details .package-hotel-description__header,
.dynamic-package-details .package-canc-policy__header {
  min-height: 30px;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding: 5px;
}
.package-details .package-overview__content,
.package-details .package-incl-excl__content,
.package-details .package-itinerary__content,
.package-details .package-hotel-description__content,
.package-details .package-canc-policy__content,
.package-hotel-highlights .package-overview__content,
.package-hotel-highlights .package-incl-excl__content,
.package-hotel-highlights .package-itinerary__content,
.package-hotel-highlights .package-hotel-description__content,
.package-hotel-highlights .package-canc-policy__content,
.dynamic-package-details .package-overview__content,
.dynamic-package-details .package-incl-excl__content,
.dynamic-package-details .package-itinerary__content,
.dynamic-package-details .package-hotel-description__content,
.dynamic-package-details .package-canc-policy__content {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 5px;
}
.package-details .package-overview__content_remarks,
.package-details .package-incl-excl__content_remarks,
.package-details .package-itinerary__content_remarks,
.package-details .package-hotel-description__content_remarks,
.package-details .package-canc-policy__content_remarks,
.package-hotel-highlights .package-overview__content_remarks,
.package-hotel-highlights .package-incl-excl__content_remarks,
.package-hotel-highlights .package-itinerary__content_remarks,
.package-hotel-highlights .package-hotel-description__content_remarks,
.package-hotel-highlights .package-canc-policy__content_remarks,
.dynamic-package-details .package-overview__content_remarks,
.dynamic-package-details .package-incl-excl__content_remarks,
.dynamic-package-details .package-itinerary__content_remarks,
.dynamic-package-details .package-hotel-description__content_remarks,
.dynamic-package-details .package-canc-policy__content_remarks {
  font-weight: 900;
}
.package-details .package-overview__content .glyphicon,
.package-details .package-incl-excl__content .glyphicon,
.package-details .package-itinerary__content .glyphicon,
.package-details .package-hotel-description__content .glyphicon,
.package-details .package-canc-policy__content .glyphicon,
.package-hotel-highlights .package-overview__content .glyphicon,
.package-hotel-highlights .package-incl-excl__content .glyphicon,
.package-hotel-highlights .package-itinerary__content .glyphicon,
.package-hotel-highlights .package-hotel-description__content .glyphicon,
.package-hotel-highlights .package-canc-policy__content .glyphicon,
.dynamic-package-details .package-overview__content .glyphicon,
.dynamic-package-details .package-incl-excl__content .glyphicon,
.dynamic-package-details .package-itinerary__content .glyphicon,
.dynamic-package-details .package-hotel-description__content .glyphicon,
.dynamic-package-details .package-canc-policy__content .glyphicon {
  color: #F3C103;
}
.package-details .package-overview__amenity,
.package-details .package-incl-excl__amenity,
.package-details .package-itinerary__amenity,
.package-details .package-hotel-description__amenity,
.package-details .package-canc-policy__amenity,
.package-hotel-highlights .package-overview__amenity,
.package-hotel-highlights .package-incl-excl__amenity,
.package-hotel-highlights .package-itinerary__amenity,
.package-hotel-highlights .package-hotel-description__amenity,
.package-hotel-highlights .package-canc-policy__amenity,
.dynamic-package-details .package-overview__amenity,
.dynamic-package-details .package-incl-excl__amenity,
.dynamic-package-details .package-itinerary__amenity,
.dynamic-package-details .package-hotel-description__amenity,
.dynamic-package-details .package-canc-policy__amenity {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 1px;
}
.package-details .package-rate,
.package-details .alternative-hotel,
.package-hotel-highlights .package-rate,
.package-hotel-highlights .alternative-hotel,
.dynamic-package-details .package-rate,
.dynamic-package-details .alternative-hotel {
  font-size: 1.125rem;
}
.package-details .package-rate_main,
.package-details .alternative-hotel_main,
.package-hotel-highlights .package-rate_main,
.package-hotel-highlights .alternative-hotel_main,
.dynamic-package-details .package-rate_main,
.dynamic-package-details .alternative-hotel_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package-details .package-rate_main:before, .package-details .package-rate_main:after,
.package-details .alternative-hotel_main:before,
.package-details .alternative-hotel_main:after,
.package-hotel-highlights .package-rate_main:before,
.package-hotel-highlights .package-rate_main:after,
.package-hotel-highlights .alternative-hotel_main:before,
.package-hotel-highlights .alternative-hotel_main:after,
.dynamic-package-details .package-rate_main:before,
.dynamic-package-details .package-rate_main:after,
.dynamic-package-details .alternative-hotel_main:before,
.dynamic-package-details .alternative-hotel_main:after {
  content: " ";
  display: table;
}
.package-details .package-rate_main:after,
.package-details .alternative-hotel_main:after,
.package-hotel-highlights .package-rate_main:after,
.package-hotel-highlights .alternative-hotel_main:after,
.dynamic-package-details .package-rate_main:after,
.dynamic-package-details .alternative-hotel_main:after {
  clear: both;
}
.package-details .package-rate_main .content,
.package-details .alternative-hotel_main .content,
.package-hotel-highlights .package-rate_main .content,
.package-hotel-highlights .alternative-hotel_main .content,
.dynamic-package-details .package-rate_main .content,
.dynamic-package-details .alternative-hotel_main .content {
  padding-left: 0;
}
.package-details .package-rate_wrapper,
.package-details .alternative-hotel_wrapper,
.package-hotel-highlights .package-rate_wrapper,
.package-hotel-highlights .alternative-hotel_wrapper,
.dynamic-package-details .package-rate_wrapper,
.dynamic-package-details .alternative-hotel_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package-details .package-rate_wrapper,
  .package-details .alternative-hotel_wrapper,
  .package-hotel-highlights .package-rate_wrapper,
  .package-hotel-highlights .alternative-hotel_wrapper,
  .dynamic-package-details .package-rate_wrapper,
  .dynamic-package-details .alternative-hotel_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package-details .package-rate_wrapper,
  .package-details .alternative-hotel_wrapper,
  .package-hotel-highlights .package-rate_wrapper,
  .package-hotel-highlights .alternative-hotel_wrapper,
  .dynamic-package-details .package-rate_wrapper,
  .dynamic-package-details .alternative-hotel_wrapper {
    max-width: 1400px;
  }
}
.package-details .package-rate_wrapper .single-content,
.package-details .alternative-hotel_wrapper .single-content,
.package-hotel-highlights .package-rate_wrapper .single-content,
.package-hotel-highlights .alternative-hotel_wrapper .single-content,
.dynamic-package-details .package-rate_wrapper .single-content,
.dynamic-package-details .alternative-hotel_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package-details .package-rate_row,
.package-details .alternative-hotel_row,
.package-hotel-highlights .package-rate_row,
.package-hotel-highlights .alternative-hotel_row,
.dynamic-package-details .package-rate_row,
.dynamic-package-details .alternative-hotel_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.package-details .package-rate_row:before, .package-details .package-rate_row:after,
.package-details .alternative-hotel_row:before,
.package-details .alternative-hotel_row:after,
.package-hotel-highlights .package-rate_row:before,
.package-hotel-highlights .package-rate_row:after,
.package-hotel-highlights .alternative-hotel_row:before,
.package-hotel-highlights .alternative-hotel_row:after,
.dynamic-package-details .package-rate_row:before,
.dynamic-package-details .package-rate_row:after,
.dynamic-package-details .alternative-hotel_row:before,
.dynamic-package-details .alternative-hotel_row:after {
  content: " ";
  display: table;
}
.package-details .package-rate_row:after,
.package-details .alternative-hotel_row:after,
.package-hotel-highlights .package-rate_row:after,
.package-hotel-highlights .alternative-hotel_row:after,
.dynamic-package-details .package-rate_row:after,
.dynamic-package-details .alternative-hotel_row:after {
  clear: both;
}
.package-details .package-rate_card,
.package-details .alternative-hotel_card,
.package-hotel-highlights .package-rate_card,
.package-hotel-highlights .alternative-hotel_card,
.dynamic-package-details .package-rate_card,
.dynamic-package-details .alternative-hotel_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.package-details .package-rate_card:before, .package-details .package-rate_card:after,
.package-details .alternative-hotel_card:before,
.package-details .alternative-hotel_card:after,
.package-hotel-highlights .package-rate_card:before,
.package-hotel-highlights .package-rate_card:after,
.package-hotel-highlights .alternative-hotel_card:before,
.package-hotel-highlights .alternative-hotel_card:after,
.dynamic-package-details .package-rate_card:before,
.dynamic-package-details .package-rate_card:after,
.dynamic-package-details .alternative-hotel_card:before,
.dynamic-package-details .alternative-hotel_card:after {
  content: " ";
  display: table;
}
.package-details .package-rate_card:after,
.package-details .alternative-hotel_card:after,
.package-hotel-highlights .package-rate_card:after,
.package-hotel-highlights .alternative-hotel_card:after,
.dynamic-package-details .package-rate_card:after,
.dynamic-package-details .alternative-hotel_card:after {
  clear: both;
}
.package-details .package-rate_footer,
.package-details .alternative-hotel_footer,
.package-hotel-highlights .package-rate_footer,
.package-hotel-highlights .alternative-hotel_footer,
.dynamic-package-details .package-rate_footer,
.dynamic-package-details .alternative-hotel_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.package-details .package-rate_footer:before, .package-details .package-rate_footer:after,
.package-details .alternative-hotel_footer:before,
.package-details .alternative-hotel_footer:after,
.package-hotel-highlights .package-rate_footer:before,
.package-hotel-highlights .package-rate_footer:after,
.package-hotel-highlights .alternative-hotel_footer:before,
.package-hotel-highlights .alternative-hotel_footer:after,
.dynamic-package-details .package-rate_footer:before,
.dynamic-package-details .package-rate_footer:after,
.dynamic-package-details .alternative-hotel_footer:before,
.dynamic-package-details .alternative-hotel_footer:after {
  content: " ";
  display: table;
}
.package-details .package-rate_footer:after,
.package-details .alternative-hotel_footer:after,
.package-hotel-highlights .package-rate_footer:after,
.package-hotel-highlights .alternative-hotel_footer:after,
.dynamic-package-details .package-rate_footer:after,
.dynamic-package-details .alternative-hotel_footer:after {
  clear: both;
}
.package-details .package-rate:before, .package-details .package-rate:after,
.package-details .alternative-hotel:before,
.package-details .alternative-hotel:after,
.package-hotel-highlights .package-rate:before,
.package-hotel-highlights .package-rate:after,
.package-hotel-highlights .alternative-hotel:before,
.package-hotel-highlights .alternative-hotel:after,
.dynamic-package-details .package-rate:before,
.dynamic-package-details .package-rate:after,
.dynamic-package-details .alternative-hotel:before,
.dynamic-package-details .alternative-hotel:after {
  content: " ";
  display: table;
}
.package-details .package-rate:after,
.package-details .alternative-hotel:after,
.package-hotel-highlights .package-rate:after,
.package-hotel-highlights .alternative-hotel:after,
.dynamic-package-details .package-rate:after,
.dynamic-package-details .alternative-hotel:after {
  clear: both;
}
.package-details .package-rate_card,
.package-details .alternative-hotel_card,
.package-hotel-highlights .package-rate_card,
.package-hotel-highlights .alternative-hotel_card,
.dynamic-package-details .package-rate_card,
.dynamic-package-details .alternative-hotel_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.package-details .package-rate_footer,
.package-details .alternative-hotel_footer,
.package-hotel-highlights .package-rate_footer,
.package-hotel-highlights .alternative-hotel_footer,
.dynamic-package-details .package-rate_footer,
.dynamic-package-details .alternative-hotel_footer {
  margin: 0;
  background-color: #f0f5f8;
}
.package-details .package-rate_footer .package-rate_details,
.package-details .alternative-hotel_footer .package-rate_details,
.package-hotel-highlights .package-rate_footer .package-rate_details,
.package-hotel-highlights .alternative-hotel_footer .package-rate_details,
.dynamic-package-details .package-rate_footer .package-rate_details,
.dynamic-package-details .alternative-hotel_footer .package-rate_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  background-color: #c7e5f6;
  padding: 0.3125rem;
}
.package-details .package-rate__category,
.package-details .alternative-hotel__category,
.package-hotel-highlights .package-rate__category,
.package-hotel-highlights .alternative-hotel__category,
.dynamic-package-details .package-rate__category,
.dynamic-package-details .alternative-hotel__category {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .package-rate__city,
.package-details .alternative-hotel__city,
.package-hotel-highlights .package-rate__city,
.package-hotel-highlights .alternative-hotel__city,
.dynamic-package-details .package-rate__city,
.dynamic-package-details .alternative-hotel__city {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .package-rate__star-rating,
.package-details .alternative-hotel__star-rating,
.package-hotel-highlights .package-rate__star-rating,
.package-hotel-highlights .alternative-hotel__star-rating,
.dynamic-package-details .package-rate__star-rating,
.dynamic-package-details .alternative-hotel__star-rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
}
.package-details .package-rate__star-rating .glyphicon,
.package-details .alternative-hotel__star-rating .glyphicon,
.package-hotel-highlights .package-rate__star-rating .glyphicon,
.package-hotel-highlights .alternative-hotel__star-rating .glyphicon,
.dynamic-package-details .package-rate__star-rating .glyphicon,
.dynamic-package-details .alternative-hotel__star-rating .glyphicon {
  color: #F3C103;
}
.package-details .package-rate__total-price,
.package-details .alternative-hotel__total-price,
.package-hotel-highlights .package-rate__total-price,
.package-hotel-highlights .alternative-hotel__total-price,
.dynamic-package-details .package-rate__total-price,
.dynamic-package-details .alternative-hotel__total-price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  color: #ec2227;
}
.package-details .package-rate__currency,
.package-details .alternative-hotel__currency,
.package-hotel-highlights .package-rate__currency,
.package-hotel-highlights .alternative-hotel__currency,
.dynamic-package-details .package-rate__currency,
.dynamic-package-details .alternative-hotel__currency {
  color: #838383;
}
.package-details .package-rate__book-button,
.package-details .alternative-hotel__book-button,
.package-hotel-highlights .package-rate__book-button,
.package-hotel-highlights .alternative-hotel__book-button,
.dynamic-package-details .package-rate__book-button,
.dynamic-package-details .alternative-hotel__book-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  background-color: #ec2227;
  color: #fff;
  padding: 0.3125rem;
}
.package-details .rate-products,
.package-hotel-highlights .rate-products,
.dynamic-package-details .rate-products {
  border: 5px solid #c7e5f6;
  padding: 0.3125rem;
}
.package-details .rate-products__header,
.package-hotel-highlights .rate-products__header,
.dynamic-package-details .rate-products__header {
  border-bottom: 1px solid #c7e5f6;
  text-transform: uppercase;
  background-color: #fff;
  color: #087dc2;
  font-weight: 400;
}
.package-details .rate-products__header:before, .package-details .rate-products__header:after,
.package-hotel-highlights .rate-products__header:before,
.package-hotel-highlights .rate-products__header:after,
.dynamic-package-details .rate-products__header:before,
.dynamic-package-details .rate-products__header:after {
  content: " ";
  display: table;
}
.package-details .rate-products__header:after,
.package-hotel-highlights .rate-products__header:after,
.dynamic-package-details .rate-products__header:after {
  clear: both;
}
.package-details .rate-products__header > h4,
.package-hotel-highlights .rate-products__header > h4,
.dynamic-package-details .rate-products__header > h4 {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels,
.package-hotel-highlights .rate-products__content .content-hotels,
.dynamic-package-details .rate-products__content .content-hotels {
  width: 100%;
  border-bottom: 1px solid #f0f5f8;
  background-color: #fff;
  font-weight: 400;
  padding-top: 0.625rem;
}
.package-details .rate-products__content .content-hotels:before, .package-details .rate-products__content .content-hotels:after,
.package-hotel-highlights .rate-products__content .content-hotels:before,
.package-hotel-highlights .rate-products__content .content-hotels:after,
.dynamic-package-details .rate-products__content .content-hotels:before,
.dynamic-package-details .rate-products__content .content-hotels:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels:after,
.package-hotel-highlights .rate-products__content .content-hotels:after,
.dynamic-package-details .rate-products__content .content-hotels:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row:before, .package-details .rate-products__content .content-hotels__details-row:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:before,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row:before,
.dynamic-package-details .rate-products__content .content-hotels__details-row:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels__details-row:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__name, .package-details .rate-products__content .content-hotels__details-row .hotel__city, .package-details .rate-products__content .content-hotels__details-row .hotel__roomtype, .package-details .rate-products__content .content-hotels__details-row .hotel__star-rating, .package-details .rate-products__content .content-hotels__details-row .hotel__no-days,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__name,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__city,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__roomtype,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__no-days,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__name,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__city,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__roomtype,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__no-days {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__city,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__city,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__city {
  text-transform: uppercase;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__star-rating,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon {
  color: #F3C103;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights {
  width: 100%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights:before, .package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:before,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:before,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights > button,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights > button,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights > button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  color: #087dc2;
  background-color: #c7e5f6;
}

.dp-hotel-room-list {
  padding-top: 0.9375rem;
}
.dp-hotel-room-list:before, .dp-hotel-room-list:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list:after {
  clear: both;
}
.dp-hotel-room-list_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.dp-hotel-room-list_main:before, .dp-hotel-room-list_main:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list_main:after {
  clear: both;
}
.dp-hotel-room-list_main .content {
  padding-left: 0;
}
.dp-hotel-room-list_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .dp-hotel-room-list_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .dp-hotel-room-list_wrapper {
    max-width: 1400px;
  }
}
.dp-hotel-room-list_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.dp-hotel-room-list_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.dp-hotel-room-list_row:before, .dp-hotel-room-list_row:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list_row:after {
  clear: both;
}
.dp-hotel-room-list__header {
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}
.dp-hotel-room-list__header:before, .dp-hotel-room-list__header:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list__header:after {
  clear: both;
}
.dp-hotel-room-list__header_total-price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-color: #ccebfd;
}
.dp-hotel-room-list__header_total-price:before, .dp-hotel-room-list__header_total-price:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list__header_total-price:after {
  clear: both;
}
.dp-hotel-room-list__header_total-price .total-price__label, .dp-hotel-room-list__header_total-price .total-price__value {
  float: left;
}
.dp-hotel-room-list__header_total-price .total-price__label {
  color: #087dc2;
  font-size: 1rem;
}
.dp-hotel-room-list__header_total-price .total-price__value {
  margin-top: -0.3125rem;
  margin-left: 0.5rem;
}
.dp-hotel-room-list__header_total-price .total-price__value_amount {
  color: #ec2227;
  font-family: "Open Sans";
  font-size: 1.25rem;
  margin-left: 0.25rem;
}
.dp-hotel-room-list__header_change-room {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: right;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-color: #ccebfd;
}
.dp-hotel-room-list__header_change-room:before, .dp-hotel-room-list__header_change-room:after {
  content: " ";
  display: table;
}
.dp-hotel-room-list__header_change-room:after {
  clear: both;
}
.dp-hotel-room-list__header_change-room .change-room__button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
  font-weight: 700;
}
.dp-hotel-room-list__header_change-room .change-room__button:focus, .dp-hotel-room-list__header_change-room .change-room__button.focus, .dp-hotel-room-list__header_change-room .change-room__button:active:focus, .dp-hotel-room-list__header_change-room .change-room__button:active.focus, .dp-hotel-room-list__header_change-room .change-room__button.active:focus, .dp-hotel-room-list__header_change-room .change-room__button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp-hotel-room-list__header_change-room .change-room__button:hover, .dp-hotel-room-list__header_change-room .change-room__button:focus, .dp-hotel-room-list__header_change-room .change-room__button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.dp-hotel-room-list__header_change-room .change-room__button:active, .dp-hotel-room-list__header_change-room .change-room__button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp-hotel-room-list__header_change-room .change-room__button.disabled, .dp-hotel-room-list__header_change-room .change-room__button[disabled], fieldset[disabled] .dp-hotel-room-list__header_change-room .change-room__button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.hotel-room-list__room-container {
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  color: #087dc2;
  font-size: 1rem;
  padding-top: 0.25rem;
}
.hotel-room-list__room-container:before, .hotel-room-list__room-container:after {
  content: " ";
  display: table;
}
.hotel-room-list__room-container:after {
  clear: both;
}
.hotel-room-list__room-container .room-container__room-number {
  float: left;
  width: 10%;
}
.hotel-room-list__room-container .room-container__room-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  width: 50%;
}
.hotel-room-list__room-container .room-container__room-detail_binding-rate-applicable-flag {
  font-size: 1.125rem;
  color: #087dc2;
}
.hotel-room-list__room-container .room-container__room-status {
  float: left;
  width: 20%;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.hotel-room-list__room-container .room-container__room-price {
  float: right;
}
.hotel-room-list__room-container .room-container__room-price_currency {
  color: #838383;
  font-size: 0.75rem;
}
.hotel-room-list__room-container .room-container__room-price_amount {
  color: #ec2227;
  font-family: "Open Sans";
  font-size: 1.25rem;
}
.hotel-room-list__room-container .room-container__room-price_adhoc {
  float: left;
  margin-top: 0.3rem;
  width: 1.5rem;
}
.hotel-room-list__room-container .room-container__room-extra-details {
  float: right;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  width: 100%;
}
.hotel-room-list__room-container .room-container__room-extra-details:before, .hotel-room-list__room-container .room-container__room-extra-details:after {
  content: " ";
  display: table;
}
.hotel-room-list__room-container .room-container__room-extra-details:after {
  clear: both;
}
.hotel-room-list__room-container .room-container__room-extra-details_link {
  cursor: pointer;
}
.hotel-room-list__room-container .room-container__room-extra-details_link:before, .hotel-room-list__room-container .room-container__room-extra-details_link:after {
  content: " ";
  display: table;
}
.hotel-room-list__room-container .room-container__room-extra-details_link:after {
  clear: both;
}

.dp-sightseeing-option-row {
  padding-top: 0.9375rem;
}
.dp-sightseeing-option-row:before, .dp-sightseeing-option-row:after {
  content: " ";
  display: table;
}
.dp-sightseeing-option-row:after {
  clear: both;
}
.dp-sightseeing-option-row__body {
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  padding: 0.5rem;
  padding-right: 0;
}
.dp-sightseeing-option-row__body:before, .dp-sightseeing-option-row__body:after {
  content: " ";
  display: table;
}
.dp-sightseeing-option-row__body:after {
  clear: both;
}
.dp-sightseeing-option-row__body .option-select {
  padding-right: 1.125rem;
}
.dp-sightseeing-option-row__body .option-container__option-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  width: 40%;
}
.dp-sightseeing-option-row__body .option-container__option-detail_binding-rate-applicable-flag {
  font-size: 1.125rem;
  color: #087dc2;
}
.dp-sightseeing-option-row__body .option-container__option-detail .description__sightseeing {
  border: none;
}
.dp-sightseeing-option-row__body .option-container__option-detail .description__sightseeing_name {
  font-weight: 600;
}
.dp-sightseeing-option-row__body .option-container__option-status {
  float: left;
  width: 20%;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.dp-sightseeing-option-row__body .option-container__option-price {
  display: inline-block;
  width: 20%;
}
.dp-sightseeing-option-row__body .option-container__option-price_currency {
  color: #838383;
  font-size: 0.75rem;
}
.dp-sightseeing-option-row__body .option-container__option-price_amount {
  color: #ec2227;
  font-family: "Open Sans";
  font-size: 1.25rem;
}
.dp-sightseeing-option-row__body .option-container__option-price_adhoc {
  float: left;
  margin-top: 0.3rem;
  width: 1.5rem;
}
.dp-sightseeing-option-row__body_change-option {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: right;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-color: #ccebfd;
}
.dp-sightseeing-option-row__body_change-option:before, .dp-sightseeing-option-row__body_change-option:after {
  content: " ";
  display: table;
}
.dp-sightseeing-option-row__body_change-option:after {
  clear: both;
}
.dp-sightseeing-option-row__body_change-option .change-option__button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
  font-weight: 700;
}
.dp-sightseeing-option-row__body_change-option .change-option__button:focus, .dp-sightseeing-option-row__body_change-option .change-option__button.focus, .dp-sightseeing-option-row__body_change-option .change-option__button:active:focus, .dp-sightseeing-option-row__body_change-option .change-option__button:active.focus, .dp-sightseeing-option-row__body_change-option .change-option__button.active:focus, .dp-sightseeing-option-row__body_change-option .change-option__button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dp-sightseeing-option-row__body_change-option .change-option__button:hover, .dp-sightseeing-option-row__body_change-option .change-option__button:focus, .dp-sightseeing-option-row__body_change-option .change-option__button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.dp-sightseeing-option-row__body_change-option .change-option__button:active, .dp-sightseeing-option-row__body_change-option .change-option__button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.dp-sightseeing-option-row__body_change-option .change-option__button.disabled, .dp-sightseeing-option-row__body_change-option .change-option__button[disabled], fieldset[disabled] .dp-sightseeing-option-row__body_change-option .change-option__button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dp-sightseeing-option-row__body_options {
  padding-top: 0.9375rem;
}

.dp-sightseeig-option {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}