@use "sass:math";

.dp-change-product {

	.modal__content {
		width:100%;
    	margin-top: 0px;
	    margin-bottom: 0px;
	    padding-top: 0px;
	    padding-bottom: 0px;
   		margin-top: 3rem;
   		max-height: 500px;
    }
    .modal-body {
	    padding-right: 0px;
	    padding-left: 0px;
    
    }
}

.dp-hotel-highlights {
    @include clearfix;
    height:500px;
    
	.highlights-modal {
		&__hotel-details {
			float:right;
			width:75%;
			height:100%;
			margin-top: 10px;	
		}
		
	}
	
	
	
	.sidebar {
		&__back-button {
    		margin: 20px;
    		background-color: white;
		    border: 5px solid $brand-color-2;
		    padding: 25px;
		    
    		button {
    			cursor: pointer;
    			padding-top: math.div($base-padding, 6);
		        padding-bottom: math.div($base-padding, 6);
		        border: none;
		        line-height: inherit;
		        border-radius: 0;
		        background-image: none;
		        font-weight: 700;
		        text-align: center;
		        text-transform: uppercase;
		        font-size: 10px;
		        background-color: $brand-color-1;
		        color: $light;
		        display: block;
		        &:hover {
		          background-color: darken($brand-color-1, 20);
		        }
    		}
		}
		
		&__tabs {
			width:100%;
			height:80%;
			padding-bottom: 10px;
			>li {
				padding-left: 20px;
				padding-right: 20px;
			    text-align: left;
			    position: relative;
			    
			    >a {
			    	color:$brand-color-2;
			    	padding-top: 15px;
				    padding-bottom: 15px;
				    display: block;
				    text-decoration: none;
				    cursor: pointer;
			    }
			    
			    &:hover {
			    	background-color: $brand-color-2;
			    	a {
			    		color: $light;
			    	}
			    }
			    
			    &:after {
			    	content: "";
			    	width: 80%;
			    	margin: 0 auto;
			    	height: 1px;
			    	background-color: white;
			    	position: absolute;
			    	top: 100%;
			    }
			}
			.active {
				background-color: $light;
				a {
					color: $brand-color-1;
				}
			}
		}
	}
	
	.hotel-details {
		&__hotel-info {
			@include clearfix;
			width:100%;
			height:20%;
		}
		
		&__content {
			width:100%;
			height:80%;
			padding-left: 15px;
		}
	}
	
	.hotel-info {
		&__hotel-image {
			float: left;
			padding-left: 15px;
			width:20%;
			
			.result__thumbnail {
				width: 100%;
    			height: 90px;
			}
		}
		
		&__hotel-description {
			float: left;
			width: 80%;
		}
	}
	
	.description__hotel {
		padding-left: 10px;
		width:100%;
		
		&_name {
			color: $brand-color-2;
		    font-weight: 400;
		    text-align: left;
		    font-size: 18px;
		}
	}
	
	.hotel-slideshow__header {
		min-height:30px;
		background-color:$hotel-contact-header-bg-color;
		color:$hotel-contact-header-text-color;
		text-transform: uppercase;
		padding-left: 5px;
		padding-top: 5px;
		padding-right: 5px;
		padding-bottom: 5px;
		margin-bottom: 10px;
	}

}
.dp{
	&__search_result {
		.hotel-room-detail {
			&__room-filter {
				width: 25%;
			}
			&__container-view {
				width: 75%; 	
			}
			&__toggle-filter-btn {
				width: 15%;
			} 
		}
		.highlights-modal {
			&__sidebar {
				float: left;
				height: 3rem;
				top: 5.5rem;
				position: fixed;
				width:100%;
				padding-right:2rem;
				&_hotel-name {
			      width: 50%;
		        }
		        &_tabs {
			       width: 50%;
			       >li {
		    		font-size: 0.62rem;
			       }
			    }
			}
			&__menu{
	    		top: 3rem;
	    	}
		}
	}
}
	


