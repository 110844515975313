.hotel-room-list {
    &__room-container {

        border: 1px solid $gray-lighting;
        border-radius: 0.25rem;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, .2);
        margin-bottom: .5rem;
        padding: .5rem;
        @include clearfix();
        color: $brand-color-2;
        font-size: 1rem;
        padding-top: .25rem;

        .room-container {
            &__room {
                &-number {
                    float: left;
                    width: 10%;
                }

                &-detail {
                    @include text-overflow;
                    float: left;
                    width: 50%;
                 	&_binding-rate-applicable-flag {
						font-size: $font-size-number-small;
						color: $brand-color-2;
					}
                }

                &-status {
                    float: left;
                    width: 20%;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    margin-top: 0.50rem;
                }

                &-price {
                    float: right;

                    &_currency {
                        color: $brand-color-5;
                        font-size: 0.75rem;
                    }

                    &_amount {
                        color: $brand-color-1;
                        font-family: $font-family-number;
                        font-size: 1.25rem;
                    }
                    
                    &_adhoc {
                    	float: left;
                    	margin-top: 0.3rem;
                    	width: 1.5rem;
                    }
                }
            }
            &__room-extra-details{
	 			@include clearfix;
				float: right;
    			padding-top: 0.5rem;
    			font-size: 0.75rem;
    			width: 100%;
    			&_link{
					@include clearfix;
					cursor: pointer;
				}
			}
        }
    }
}