@mixin result-row-header_private{
	text-transform:uppercase;
	color:$brand-color-2;
	border-bottom: 1px solid darken($brand-color-4,20);
	padding-top: $base-padding*0.2;
	padding-bottom: $base-padding*0.2;
	padding-left: $base-padding*0.2;
    padding-right: $base-padding*0.2;		
    margin-bottom: $base-margin*0.2;
    margin-left: -$base-margin*0.5;
    margin-right: -15px;
}

@mixin  result-row-header{ 
	@include result-row-header_private; 
}