@use "sass:math";

.dp-hotel-room-list {
    @include clearfix;
    @include create-main-wrapper;
    @include create-row;
    padding-top: $base-padding*0.5;

    &__header {
        @include clearfix;
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-lighting;
        border-radius: 0.25rem;
        margin-bottom: .5rem;
        padding: .5rem;
        padding-right: 0;
        padding-left: 0;
        width: 100%;

        &_total-price {
            @include clearfix;
            @include make-four-fifth;
            float: left;
            padding-left: math.div($base-padding, 3);
            padding-right: math.div($base-padding, 3);
            border-color: $row-book-border-color;

            .total-price {

                &__label,
                &__value {
                    float: left;
                }

                &__label {
                    color: $brand-color-2;
                    font-size: 1rem;
                }

                &__value {
                    margin-top: -.3125rem;
                    margin-left: .5rem;

                    &_amount {
                        color: $brand-color-1;
                        font-family: $font-family-number;
                        font-size: 1.25rem;
                        margin-left: .25rem;
                    }
                }
            }
        }

        &_change-room {
            @include clearfix;
            @include make-one-fifth;
            float: right;
            padding-left: math.div($base-padding, 3);
            padding-right: math.div($base-padding, 3);
            border-color: $row-book-border-color;

            .change-room {
                &__button {
                    @include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
                    @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
                    text-transform: uppercase;
                    width: 100%;
                    font-weight: 700;
                }
            }
        }
    }

}